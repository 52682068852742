import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface IBreadcrumb {
  title: string;
  link?: string | any[];
  queryParams?: any;
  isPage?: boolean;
}
@Injectable({
  providedIn: 'root',
})
export class GeneralService {
  breadcrumbs$: BehaviorSubject<Array<IBreadcrumb> | undefined> =
    new BehaviorSubject(undefined);

  btnLoader$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  refreshFlushes$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor() {}

  getBreadcrumbs() {
    return this.breadcrumbs$.asObservable();
  }

  setBreadcrumbs(data: IBreadcrumb[]) {
    this.breadcrumbs$.next(data);
  }

  get btnLoader() {
    return this.btnLoader$.asObservable();
  }

  setBtnLoader(value: boolean) {
    this.btnLoader$.next(value);
  }

  get refreshFlushes() {
    return this.refreshFlushes$.asObservable();
  }

  setRefreshFlushes(value: boolean) {
    this.refreshFlushes$.next(value);
  }

  /**
   * Saves the file on the client's machine via FileSaver library.
   *
   * @param buffer The data that need to be saved.
   * @param fileName File name to save as.
   * @param fileType File type to save as.
   */
  private saveAsFile(buffer: any, fileName: string, fileType: string): void {
    const data: Blob = new Blob([buffer], { type: fileType });
    const a = document.createElement('a');
    a.href = URL.createObjectURL(data);
    a.download = fileName;
    a.click();
  }
  /**
   * Creates an array of data to CSV. It will automatically generate a title row based on object keys.
   *
   * @param rows array of data to be converted to CSV.
   * @param fileName filename to save as.
   * @param columns array of object properties to convert to CSV. If skipped, then all object properties will be used for CSV.
   */
  public exportToCsv(
    rows: object[],
    fileName: string,
    columns?: string[]
  ): string {
    if (!rows || !rows.length) {
      return;
    }
    const separator = ',';
    const keys = Object.keys(rows[0]).filter(k => {
      if (columns?.length) {
        return columns.includes(k);
      } else {
        return true;
      }
    });
    const csvContent =
      keys.join(separator) +
      '\n' +
      rows
        .map(row => {
          return keys
            .map(k => {
              let cell = row[k] === null || row[k] === undefined ? '' : row[k];
              cell =
                cell instanceof Date
                  ? cell.toLocaleString()
                  : cell.toString().replace(/"/g, '""');
              if (cell.search(/("|,|\n)/g) >= 0) {
                cell = `"${cell}"`;
              }
              return cell;
            })
            .join(separator);
        })
        .join('\n');
    this.saveAsFile(csvContent, `${fileName}.csv`, 'text/csv');
  }
}
