export const environment = {
  production: true,
  apiPrefix: 'https://',
  apiSuffix: '.api.creevx.com/api',
  envVar: {
      NAME: "CBRE",
      LOGO: "https://scappsab.sirv.com/Images/cbre-log.png",
      LOGO_WHITE: "https://scappsab.sirv.com/Images/CBRE-white-Logo.png",
      PRIMARY_COLOR: "#003F2D",
      FAVICON: "https://www.cbre.com/-/media/project/cbre/dotcom/global/unsorted/favicon_lg.png"
},
};
