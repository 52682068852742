export class User {
  _id: string;
  email: string;
  firstName: string;
  lastName: string;
  role: {
    name: string;
    permissions: IPermission;
  };
  token?: string;
  tenantId?: string;
  changedDefaultPassword: boolean;
}

export const defaultPermissions: IPermission = {
  dashboard: { read: false, write: false },
  buildings: { read: false, write: false },
  reports: { read: false, write: false },
  assets: { read: false, write: false },
  issues: { read: false, write: false },
  settings: { read: false, write: false },
  devices: { read: false, write: false },
  rules: { read: false, write: false },
  schedules: { read: false, write: false },
  users: { read: false, write: false },
};

export interface IPermission {
  dashboard: {
    read: boolean;
    write: boolean;
  };
  buildings: {
    read: boolean;
    write: boolean;
  };
  reports: {
    read: boolean;
    write: boolean;
  };
  assets: {
    read: boolean;
    write: boolean;
  };
  issues: {
    read: boolean;
    write: boolean;
  };
  settings: {
    read: boolean;
    write: boolean;
  };
  devices: {
    read: boolean;
    write: boolean;
  };
  rules: {
    read: boolean;
    write: boolean;
  };
  schedules: {
    read: boolean;
    write: boolean;
  };
  users: {
    read: boolean;
    write: boolean;
  };
}
