import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { delay, finalize, Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { AuthenticationService } from '../_services/authentication.service';
import { GeneralService } from '../_services/general.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  methodForLoading = ['PUT', 'PATCH', 'POST', 'DELETE'];
  constructor(
    private authenticationService: AuthenticationService,
    private generalService: GeneralService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Set loader to visible
    this.authenticationService.setLoading(true);
    if (this.methodForLoading.includes(request.method)) {
      // console.log(request.method);
      this.generalService.setBtnLoader(true);
    }

    // add auth header with jwt if user is logged in and request is to the api url
    const currentUser = this.authenticationService.currentUserValue;
    const isLoggedIn = currentUser && currentUser.token;
    const isApiUrl = request.url.startsWith(environment.apiPrefix);
    if (isLoggedIn && isApiUrl) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${currentUser.token}`,
        },
      });
    }

    return next.handle(request).pipe(
      delay(0),
      finalize(() => {
        this.authenticationService.setLoading(false);
        this.generalService.setBtnLoader(false);
      })
    );
  }
}
