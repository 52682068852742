import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  Event,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import { ROUTES } from '../sidebar/sidebar.component';

import { User } from '../../_models/user';
import { AuthenticationService } from '../../_services/authentication.service';

import { Location } from '@angular/common';
import { Subscription } from 'rxjs';
import { GeneralService } from 'src/app/_services/general.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, OnDestroy {
  public focus;
  public listTitles: any[];
  public location: Location;
  sidenavOpen: boolean = true;
  currentUser: User;
  tenantId: string;
  accountKey: string;
  breadcrumbs$: any;
  sub$ = new Subscription();
  accounts: Map<string, User> | null = null;

  constructor(
    location: Location,
    private router: Router,
    private authenticationService: AuthenticationService,
    private generalService: GeneralService
  ) {
    this.breadcrumbs$ = this.generalService.getBreadcrumbs();
    this.location = location;

    this.authenticationService.currentAllAccountsValue.subscribe(accounts => {
      this.accounts = accounts;
    });

    const userSub$ = this.authenticationService.currentAccountValue.subscribe(
      user => {
        if (user) {
          this.currentUser = user;
          this.tenantId = user.tenantId;
          this.accountKey = `account-${user.tenantId}-${user._id}`;
        }
      }
    );
    // const authSub$ = this.authenticationService.tenantId.subscribe(
    //   x => (this.tenantId = x.toUpperCase())
    // );
    const routerSub$ = this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        // Show loading indicator
      }
      if (event instanceof NavigationEnd) {
        // Hide loading indicator

        if (window.innerWidth < 1200) {
          document.body.classList.remove('g-sidenav-pinned');
          document.body.classList.add('g-sidenav-hidden');
          this.sidenavOpen = false;
        }
      }

      if (event instanceof NavigationError) {
        // Hide loading indicator

        // Present error to user
        console.log(event.error);
      }
    });
    this.sub$.add(userSub$);
    // this.sub$.add(authSub$);
    this.sub$.add(routerSub$);
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }

  ngOnInit() {
    this.listTitles = ROUTES.filter(listTitle => listTitle);
  }

  search(event) {
    let searchTerm = event.target.value;
    this.router.navigate([`/search/${searchTerm}`]);
  }

  getTitle() {
    var titlee = this.location.prepareExternalUrl(this.location.path());
    if (titlee.charAt(0) === '#') {
      titlee = titlee.slice(1);
    }

    for (var item = 0; item < this.listTitles.length; item++) {
      if (this.listTitles[item].path === titlee) {
        return this.listTitles[item].title;
      }
    }
    return 'Dashboard';
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/auth/login']);
  }

  openSearch() {
    document.body.classList.add('g-navbar-search-showing');
    setTimeout(function () {
      document.body.classList.remove('g-navbar-search-showing');
      document.body.classList.add('g-navbar-search-show');
    }, 150);
    setTimeout(function () {
      document.body.classList.add('g-navbar-search-shown');
    }, 300);
  }
  closeSearch() {
    document.body.classList.remove('g-navbar-search-shown');
    setTimeout(function () {
      document.body.classList.remove('g-navbar-search-show');
      document.body.classList.add('g-navbar-search-hiding');
    }, 150);
    setTimeout(function () {
      document.body.classList.remove('g-navbar-search-hiding');
      document.body.classList.add('g-navbar-search-hidden');
    }, 300);
    setTimeout(function () {
      document.body.classList.remove('g-navbar-search-hidden');
    }, 500);
  }
  openSidebar() {
    if (document.body.classList.contains('g-sidenav-pinned')) {
      document.body.classList.remove('g-sidenav-pinned');
      document.body.classList.add('g-sidenav-hidden');
      this.sidenavOpen = false;
    } else {
      document.body.classList.add('g-sidenav-pinned');
      document.body.classList.remove('g-sidenav-hidden');
      this.sidenavOpen = true;
    }
  }
  toggleSidenav() {
    if (document.body.classList.contains('g-sidenav-pinned')) {
      document.body.classList.remove('g-sidenav-pinned');
      document.body.classList.add('g-sidenav-hidden');
      this.sidenavOpen = false;
    } else {
      document.body.classList.add('g-sidenav-pinned');
      document.body.classList.remove('g-sidenav-hidden');
      this.sidenavOpen = true;
    }
  }

  onSwitch(account: any) {
    console.log(`onSwitch account`, account)
    this.authenticationService.switchAccount(account);
  }
}
