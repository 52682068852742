import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { DataService } from 'src/app/_services/data.service';
import { IPermission, User } from 'src/app/_models/user';
import { Observable, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';

var misc: any = {
  sidebar_mini_active: true,
};

export interface RouteInfo {
  path: string;
  title: string;
  type: string;
  authRequired?: boolean;
  icontype: string;
  collapse?: string;
  isCollapsed?: boolean;
  isCollapsing?: any;
  children?: ChildrenItems[];
}

export interface ChildrenItems {
  path: string;
  title: string;
  type?: string;
  collapse?: string;
  children?: ChildrenItems2[];
  isCollapsed?: boolean;
}
export interface ChildrenItems2 {
  path?: string;
  title?: string;
  type?: string;
}

let filteredMenuItem: any[];

//Menu Items
export const ROUTES: RouteInfo[] = [
  {
    path: 'dashboard',
    title: 'Dashboard',
    authRequired: true,
    type: 'link',
    icontype: 'fas fa-chart-pie text-primary',
  },
  {
    path: 'buildings',
    title: 'Buildings',
    authRequired: true,
    type: 'link',
    icontype: 'fas fa-building text-primary',
  },
  {
    path: 'reports',
    title: 'Reports',
    authRequired: false,
    type: 'link',
    icontype: 'fas fa-file-lines text-primary',
  },
  {
    path: '',
    title: 'System',
    type: 'sub',
    icontype: 'ni ni-ui-04 text-primary',
    collapse: 'components',
    isCollapsed: true,
    children: [
      { path: 'settings', title: 'Settings', type: 'link' },
      { path: 'assets', title: 'Assets', type: 'link' },
      { path: 'rules', title: 'Flush Rules', type: 'link' },
      { path: 'schedules', title: 'Scheduled Flushing', type: 'link' },
      { path: 'users', title: 'Users', type: 'link' },
      /* { path: 'gateways', title: 'Gateways', type: 'link' }, */
    ],
  },
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, OnDestroy {
  public menuItems = [];
  public isCollapsed = true;
  currentUser: User;
  settings;
  apiHealth;
  sub$ = new Subscription();
  permissions$: Observable<IPermission>;
  logo = environment.envVar.LOGO;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private dataService: DataService
  ) {
    const userSub$ = this.authenticationService.currentAccountValue.subscribe(
      user => {
        if (user) this.currentUser = user;
        this.permissions$ =
          this.authenticationService.permissions$.asObservable();
      }
    );
    const healthSub$ = this.dataService.getHealth().subscribe((data: any) => {
      if (data) this.apiHealth = data;
    });
    this.sub$.add(userSub$);
    this.sub$.add(healthSub$);
  }

  ngOnDestroy() {
    this.sub$.unsubscribe();
  }

  ngOnInit() {
    ROUTES.forEach(menuItem => {
      if (menuItem.children) {
        let filteredMenuItem = Object.assign({}, menuItem);
        filteredMenuItem.children = [];
        menuItem.children.forEach(subMenuItem => {
          filteredMenuItem.children.push(subMenuItem);
        });
        this.menuItems.push(filteredMenuItem);
      } else {
        this.menuItems.push(menuItem);
      }
    });
    this.router.events.subscribe(event => {
      this.isCollapsed = true;
    });
  }

  onMouseEnterSidenav() {
    if (!document.body.classList.contains('g-sidenav-pinned')) {
      document.body.classList.add('g-sidenav-show');
    }
  }
  onMouseLeaveSidenav() {
    if (!document.body.classList.contains('g-sidenav-pinned')) {
      document.body.classList.remove('g-sidenav-show');
    }
  }
  minimizeSidebar() {
    const sidenavToggler =
      document.getElementsByClassName('sidenav-toggler')[0];
    const body = document.getElementsByTagName('body')[0];
    if (body.classList.contains('g-sidenav-pinned')) {
      misc.sidebar_mini_active = true;
    } else {
      misc.sidebar_mini_active = false;
    }
    if (misc.sidebar_mini_active === true) {
      body.classList.remove('g-sidenav-pinned');
      body.classList.add('g-sidenav-hidden');
      sidenavToggler.classList.remove('active');
      misc.sidebar_mini_active = false;
    } else {
      body.classList.add('g-sidenav-pinned');
      body.classList.remove('g-sidenav-hidden');
      sidenavToggler.classList.add('active');
      misc.sidebar_mini_active = true;
    }
  }
}
