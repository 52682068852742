import { Component, OnDestroy } from '@angular/core';
import {
  Event,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';

import { PrimeNGConfig } from 'primeng/api';
import { User } from './_models/user';
import { AuthenticationService } from './_services/authentication.service';
import { GeneralService } from './_services/general.service';
import { DataService } from './_services/data.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy {
  currentUser: User;
  sub$ = new Subscription();
  loader = false;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private generalService: GeneralService,
    private dataService: DataService
  ) {
    // console.log(environment.envVar);
    if (environment.envVar.PRIMARY_COLOR) {
      document.documentElement.style.setProperty(
        '--primary-color',
        environment.envVar.PRIMARY_COLOR
      );
      const favIcon: HTMLLinkElement = document.querySelector('#appIcon');
      favIcon.href = environment.envVar.FAVICON;
    }

    this.generalService.btnLoader.subscribe(loading => {
      this.forEachButton(b => (b.disabled = loading));
    });

    const userSub$ = this.authenticationService.currentAccountValue.subscribe(
      x => {
        this.currentUser = x;
        if (x) {
          // Fetch Sentinels for global usage
          this.dataService.fetchSentinels();
          // Fetch assets for global usage
          this.dataService.fetchAssets();
        }
      }
    );
    this.sub$.add(userSub$);

    const routerSub$ = this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        // Show loading indicator
        window.scrollTo(0, 0);
      }

      if (event instanceof NavigationEnd) {
        // Hide loading indicator
      }

      if (event instanceof NavigationError) {
        // Hide loading indicator

        // Present error to user
        console.log(event.error);
      }
    });
    this.sub$.add(routerSub$);
  }

  forEachButton(func) {
    let elements = document.getElementsByTagName('button');
    for (let i = 0, len = elements.length; i < len; i++) {
      func(elements[i]);
    }
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }
}
