export const frequencies = [{ name: 'weekly' }, { name: 'monthly' }];

export const Units = [
  { name: 'minutes' },
  { name: 'hours' },
  { name: 'days' },
  { name: 'weeks' },
  // { name: "months", value: 'month', timeCondition: true, conditionText: "Minutes past the hour", inputType: 'number' }
];

export const Timezones = [{ name: 'Europe/Dublin' }, { name: 'Europe/Berlin' }];

export const Assets = [
  { name: 'Any' },
  { name: 'Sentinel' },
  { name: 'Source' },
];

export const Temperatures = [
  { name: 'All' },
  { name: 'Hot' },
  { name: 'Mixed' },
  { name: 'Cold' },
];

export const Days = [
  { name: 'Monday', shortName: 'Mon', value: 'M', number: 1 },
  { name: 'Tuesday', shortName: 'Tue', value: 'T', number: 2 },
  { name: 'Wednesday', shortName: 'Wed', value: 'W', number: 3 },
  { name: 'Thursday', shortName: 'Thu', value: 'T', number: 4 },
  { name: 'Friday', shortName: 'Fri', value: 'F', number: 5 },
  { name: 'Saturday', shortName: 'Sat', value: 'S', number: 6 },
  { name: 'Sunday', shortName: 'Sun', value: 'S', number: 7 },
];

export const FlushOptions = [1, 2, 3, 5];
